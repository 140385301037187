<template>
  <div>
    <Breadcrumbs page_title="course" :items="breadcrumbs" />

    <v-container fluid>
      <v-row v-if="loading" class="mt-5">
        <v-col cols="2" v-for="n in 4" :key="n">
          <v-skeleton-loader
            class="mx-auto"
            max-height="180"
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else class="mt-5">
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="4"
          lg="2"
          v-for="(item, i) in cardItems"
          :key="i"
        >
          <v-card
            style="border-radius: 16px"
            :style="[
              item.selected == true
                ? { border: '2px solid ' + item.bgcolor }
                : {},
            ]"
            height="220"
            @click="selectCourseCategory(item)"
          >
            <v-card-text justify="center" align="center" style="height: 100%">
              <div
                class="imgBg"
                :style="[
                  item.bgcolor
                    ? { 'background-color': item.bgcolor }
                    : { 'background-color': '#a6cc39' },
                ]"
              ></div>

              <v-img :src="item.image" width="52" class="courseImg"></v-img>

              <p
                class="text-center mt-5"
                style="font-size: 20px; color: #424242"
              >
                {{ $t(item.title) }}
              </p>
              <p
                v-if="item.courseCount > 1"
                class="coursecounttext"
                :style="{
                  color: item.title === 'complete' ? '#4FB050' : '#8A0000',
                  fontSize: '16px',
                }"
              >
                {{ item.courseCount }} {{ $t("coursess") }}
              </p>
              <p
                v-else-if="item.courseCount === 1"
                class="coursecounttext"
                :style="{
                  color: item.title === 'complete' ? '#4FB050' : '#8A0000',
                  fontSize: '16px',
                }"
              >
                {{ item.courseCount }} {{ $t("courses") }}
              </p>
              <p
                v-else
                class="coursecounttext"
                :style="{
                  color: item.title === 'complete' ? '#4FB050' : '#8A0000',
                  fontSize: '16px',
                }"
              >
                0 {{ $t("course") }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="showCourseList">
        <v-col cols="12" md="10" lg="8">
          <v-card
            style="
              width: 1314px;
              background: #ffffff 0% 0% no-repeat padding-box;
              box-shadow: 0px 3px 6px #0000000f;
              border-radius: 16px;
            "
          >
            <div class="mt-p pt-3">
              <v-text-field
                outlined
                dense
                hide-details
                clearable
                :placeholder="$t('search') + '...'"
                v-model="search"
                class="custom-padding mb-4 ml-3 mr-3"
              >
              </v-text-field>
            </div>
            <div class="pb-4 ml-4 mr-4">
              <v-list>
                <v-list-group
                  active-class="primary--text"
                  style="margin-bottom: 0.7rem"
                  class="list-Bg"
                  v-for="item in searchInLists"
                  :key="item.assignmentCourseID"
                  @click="getCourseDetailFiles(item.assignmentCourseID)"
                  append-icon
                  no-action
                >
                  <template v-slot:activator>
                    <v-row no-gutters>
                      <v-col
                        cols="8"
                        md="8"
                        lg="8"
                        xl="9"
                        class="d-flex align-center px-0"
                        style="
                          gap: 1rem;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        <v-img
                          :src="
                            item.coursePicture
                              ? item.coursePicture
                              : require('@/assets/coursedefaultimage.png')
                          "
                          alt="Logo"
                          contain
                          class="course-image"
                        ></v-img>

                        <div
                          style="
                            min-height: 79px;
                            row-gap: 1.5rem;
                            max-width: 750px;
                          "
                          class="d-flex flex-column mt-1"
                          :style="
                            $vuetify.breakpoint.mdAndUp &&
                            $vuetify.breakpoint.lgAndDown
                              ? 'width: 300px'
                              : $vuetify.breakpoint.smAndDown
                              ? 'width: 240px'
                              : 'auto'
                          "
                        >
                          <!-- <div
                            :class="textClass"
                            class="mt-0"
                            style="font-size: 18px"
                          > -->
                          <p
                            style="
                              color: #424242;
                              font-size: 17px;
                              margin-bottom: 0;
                            "
                            class="textWrappingStyle"
                          >
                            {{ item.courseName }}
                            <!-- {{ truncateText(item.courseName) || " " }} -->
                          </p>
                          <!-- </div>
                          <div
                            class="mt-0"
                            :class="textClass"
                            style="font-size: 16px"
                          > -->
                          <p
                            style="
                              color: #424242;
                              font-size: 16px;
                              margin-bottom: 0;
                            "
                            class="textWrappingStyle"
                          >
                            {{ item.description }}
                            <!-- {{ truncateText(item.description) || " " }} -->
                          </p>
                          <!-- </div> -->
                        </div>
                      </v-col>
                      <v-col cols="4" md="4" lg="4" xl="3" class="pl-2 pr-0">
                        <div class="d-flex flex-row justify-end mt-2">
                          <div
                            class="d-flex flex-column justify-space-between"
                            style="row-gap: 1.5rem"
                          >
                            <p
                              style="font-size: 16px; margin-bottom: 0"
                              class="textWrappingStyle"
                            >
                              {{
                                item.fileTotal > 1
                                  ? item.fileTotal + " " + $t("Items")
                                  : item.fileTotal === 1
                                  ? item.fileTotal + " " + $t("Item")
                                  : item.fileTotal + " " + $t("NoItem")
                              }}
                              /
                              {{ item.totalDuration }}
                            </p>
                            <p
                              style="font-size: 16px; margin-bottom: 0"
                              class="textWrappingStyle"
                            >
                              {{ $t("enddate") }}:
                              {{ formatDate(item.endDateTime) }}
                            </p>
                          </div>
                          <v-icon color="#424242" class="ml-2">
                            mdi-chevron-right
                          </v-icon>

                          <!-- <v-icon
                            :color="item.openclose ? '#424242' : '#424242'"
                            class="ml-1"
                          >
                            {{
                              item.openclose
                                ? "mdi-chevron-down"
                                : "mdi-chevron-right"
                            }}
                          </v-icon> -->
                        </div>
                      </v-col>
                    </v-row>
                  </template>

                  <div
                    style="background-color: #42424214; padding-bottom: 20px"
                  >
                    <div
                      style="background-color: #ffffff; padding-bottom: 20px"
                    ></div>
                    <v-row v-show="preTestFlag" class="mt-3">
                      <v-col cols="12" class="pl-10 pr-10">
                        <div class="videoIconContainer">
                          <v-img
                            src="@/assets/Pre-Post-Test.png"
                            alt="Logo"
                            class="video_Icon"
                            style="width: 17px; height: 21px"
                          />
                          <label class="labelVideo">
                            {{ $t("pretest") }}
                          </label>
                        </div>
                      </v-col>
                    </v-row>
                    <div class="ml-10 mr-10 mt-3">
                      <v-card
                        style="border-radius: 16px"
                        v-if="preTestFlag"
                        height="110px"
                        width="1204px"
                        @click="
                          clickPreTest(PreTestdata, item.assignmentCourseID)
                        "
                        class="d-flex align-center"
                      >
                        <template>
                          <v-row rows="12" lg="12" md="12" sm="12" no-gutters>
                            <v-col cols="9" lg="9" md="9" class="py-0">
                              <v-row align="center" no-gutters>
                                <v-col
                                  cols="auto"
                                  class="ml-3 py-1 d-flex align-center"
                                >
                                  <v-img
                                    src="@/assets/images/pretesticon_0.png"
                                    class="card-image"
                                  />
                                </v-col>
                                <v-col class="ml-5">
                                  <div class="text-left" style="color: #424242">
                                    <p style="font-size: 18px" class="mb-0">
                                      {{ PreTestdata.quizName }}
                                    </p>
                                    <p style="font-size: 13px" class="mb-0">
                                      {{ $t("questionsm") }} :
                                      <span
                                        style="font-weight: bold; color: black"
                                      >
                                        {{ PreTestdata.totalQuestion }}
                                      </span>
                                      {{ " / " }} {{ $t("limited") }} :
                                      <span
                                        style="font-weight: bold; color: black"
                                      >
                                        {{ PreTestdata.limitedSubmit }}
                                      </span>
                                    </p>
                                    <p style="font-size: 13px" class="mb-0">
                                      {{ $t("latestrating") }}
                                      <span
                                        style="font-weight: bold; color: black"
                                      >
                                        {{ PreTestdata.latestRating }}
                                      </span>
                                    </p>
                                    <p style="font-size: 13px" class="mb-0">
                                      {{ $t("status") }} :
                                      <span
                                        v-if="PreTestdata.status === 'Pass'"
                                        style="
                                          color: #4fb050;
                                          font-weight: bold;
                                        "
                                      >
                                        {{ $t("pass") }}
                                      </span>
                                      <span
                                        v-else-if="
                                          PreTestdata.status === 'Fail'
                                        "
                                        style="
                                          color: #8a0000;
                                          font-weight: bold;
                                        "
                                      >
                                        {{ $t("fail") }}
                                      </span>
                                      <span v-else style="font-weight: bold">
                                        {{ $t("notstarted") }}
                                      </span>
                                    </p>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="3"
                              md="3"
                              lg="3"
                              class="d-flex justify-end align-center"
                            >
                              <div style="padding-right: 20px">
                                <v-img
                                  v-if="
                                    PreTestdata.type == 'quiz' &&
                                    PreTestdata.completed == false
                                  "
                                  src="@/assets/images/quiz_NS.svg"
                                  width="33px"
                                  height="33px"
                                  style="margin-right: 2px !important"
                                ></v-img>
                                <v-img
                                  v-if="
                                    PreTestdata.type == 'preQuiz' &&
                                    PreTestdata.completed == false
                                  "
                                  src="@/assets/postquest.png"
                                  width="33px"
                                  height="33px"
                                  style="margin-right: 2px !important"
                                ></v-img>
                                <v-img
                                  style="font-size: 37px !important"
                                  v-if="
                                    PreTestdata.type == 'preQuiz' &&
                                    PreTestdata.completed == true &&
                                    PreTestdata.completedCount != 0
                                  "
                                  src="@/assets/rightgreen.png"
                                  width="33px"
                                  height="33px"
                                  slot="prependIcon"
                                  color="#4FB14E"
                                ></v-img>
                                <!-- <v-icon
                                style="font-size: 37px !important"
                                v-if="
                                  PreTestdata.type == 'preQuiz' &&
                                  PreTestdata.completed == true &&
                                  PreTestdata.completedCount != 0
                                "
                                slot="prependIcon"
                                color="#4FB14E"
                                >mdi-check-circle</v-icon
                              > -->
                                <!-- selectedColor -->
                                <!-- Completed but fail quiz -->
                                <v-img
                                  style="font-size: 37px !important"
                                  v-if="
                                    PreTestdata.type == 'preQuiz' &&
                                    PreTestdata.completed == true &&
                                    PreTestdata.completedCount == 0
                                  "
                                  src="@/assets/redcross.png"
                                  width="33px"
                                  height="33px"
                                  slot="prependIcon"
                                  color="#D52A2A"
                                >
                                </v-img>
                                <!-- <v-icon
                                style="font-size: 37px !important"
                                v-if="
                                  PreTestdata.type == 'preQuiz' &&
                                  PreTestdata.completed == true &&
                                  PreTestdata.completedCount == 0
                                "
                                slot="prependIcon"
                                color="#D52A2A"
                                >mdi-check-circle</v-icon
                              > -->
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                      </v-card>
                    </div>

                    <div class="mt-5" v-show="articleflag">
                      <v-col cols="12" class="pl-7 pr-8">
                        <div class="videoIconContainer">
                          <v-img
                            src="@/assets/Article.png"
                            alt="Logo"
                            class="video_Icon"
                          />
                          <label class="labelVideo">
                            {{ $t("article") }}
                          </label>
                        </div>
                      </v-col>
                    </div>
                    <div class="ml-10 mr-10 mt-3">
                      <v-card
                        style="border-radius: 16px"
                        v-show="articleflag"
                        height="110px"
                        width="1204px"
                        @click="clickArticle(Articledata)"
                      >
                        <template>
                          <v-row align="center" no-gutters>
                            <v-col
                              cols="8"
                              md="8"
                              lg="8"
                              xl="9"
                              class="d-flex align-center pl-3 mt-3"
                              style="
                                gap: 1rem;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                              "
                            >
                              <v-img
                                :src="
                                  Articledata.optimizedLink
                                    ? Articledata.optimizedLink
                                    : require('@/assets/images/article_c.svg')
                                "
                                alt="Logo"
                                contain
                                class="article-image"
                              ></v-img>

                              <div
                                style="
                                  min-height: 79px;
                                  row-gap: 1.5rem;
                                  max-width: 750px;
                                "
                                class="d-flex flex-column mt-1"
                                :style="
                                  $vuetify.breakpoint.mdAndUp &&
                                  $vuetify.breakpoint.lgAndDown
                                    ? 'width: 300px'
                                    : $vuetify.breakpoint.smAndDown
                                    ? 'width: 240px'
                                    : 'auto'
                                "
                              >
                                <p
                                  style="
                                    color: #424242;
                                    font-size: 17px;
                                    margin-bottom: 0;
                                  "
                                  class="textWrappingStyle"
                                >
                                  {{ Articledata.fileName }}
                                </p>
                              </div>
                            </v-col>
                            <v-col
                              cols="4"
                              md="4"
                              lg="4"
                              xl="3"
                              class="d-flex justify-end align-center mt-3"
                            >
                              <div
                                style="margin-right: 20px"
                                v-if="Articledata.type == 'article'"
                                class="article-container"
                              >
                                <v-img
                                  v-if="
                                    Articledata.type == 'article' &&
                                    !Articledata.isCompleted
                                  "
                                  src="@/assets/images/articleNotStarted.svg"
                                  width="32px"
                                  height="32px"
                                ></v-img>
                                <v-img
                                  v-if="
                                    Articledata.type == 'article' &&
                                    Articledata.isCompleted
                                  "
                                  src="@/assets/images/articleComplete.svg"
                                  width="32px"
                                  height="32px"
                                ></v-img>
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                      </v-card>
                    </div>

                    <v-row>
                      <v-col cols="12" class="pl-10 pr-10 pt-6">
                        <div class="videoIconContainer">
                          <v-img
                            src="@/assets/Video.png"
                            alt="Logo"
                            class="video_Icon"
                          />
                          <label class="labelVideo">
                            {{ $t("video") }}
                          </label>
                        </div>
                      </v-col>
                      <v-col
                        v-for="(dataItem, index) in courseChildItems"
                        :key="index"
                        cols="12"
                        @click="checkContentType(dataItem)"
                      >
                        <div class="ml-10 mr-10">
                          <v-card
                            style="border-radius: 16px; cursor: pointer"
                            height="112px"
                            width="1204px"
                            class="d-flex justify-center align-center"
                          >
                            <template>
                              <v-row align="center" no-gutters>
                                <v-col
                                  cols="8"
                                  md="8"
                                  lg="8"
                                  xl="9"
                                  class="d-flex align-center pl-3"
                                  style="
                                    gap: 1rem;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                  "
                                >
                                  <v-img
                                    :src="dataItem.pictureBackground"
                                    alt="Logo"
                                    contain
                                    class="course-image"
                                  ></v-img>
                                  <div
                                    style="
                                      min-height: 79px;
                                      row-gap: 0.2rem;
                                      max-width: 750px;
                                    "
                                    class="d-flex flex-column mt-1"
                                    :style="
                                      $vuetify.breakpoint.mdAndUp &&
                                      $vuetify.breakpoint.lgAndDown
                                        ? 'width: 300px'
                                        : $vuetify.breakpoint.smAndDown
                                        ? 'width: 240px'
                                        : 'auto'
                                    "
                                  >
                                    <p
                                      style="
                                        color: #424242;
                                        font-size: 16px;
                                        margin-bottom: 0;
                                      "
                                      class="textWrappingStyle mt-2"
                                    >
                                      {{ dataItem.title }}
                                    </p>
                                    <p
                                      style="
                                        color: #424242;
                                        font-size: 16px;
                                        margin-bottom: 0;
                                      "
                                      class="textWrappingStyle"
                                    >
                                      {{ dataItem.duration }}
                                      {{ $t("minutes") }}
                                    </p>
                                  </div>
                                </v-col>
                                <v-col
                                  cols="4"
                                  md="4"
                                  lg="4"
                                  xl="3"
                                  class="d-flex justify-end align-center"
                                >
                                  <div style="padding-right: 20px">
                                    <v-img
                                      v-if="
                                        dataItem.type == 'video' &&
                                        dataItem.isComplete == null &&
                                        dataItem.progress == null
                                      "
                                      style="font-size: 37px !important"
                                      slot="prependIcon"
                                      color="#008AFF"
                                      src="@/assets/VideoPlay.png.svg"
                                      width="32px"
                                      height="32px"
                                    ></v-img>
                                    <!-- In Progres -->
                                    <v-img
                                      v-if="
                                        dataItem.type == 'video' &&
                                        dataItem.isComplete == false &&
                                        dataItem.progress != null
                                      "
                                      style="font-size: 37px !important"
                                      slot="prependIcon"
                                      color="#E0BF0B"
                                      src="@/assets/yelloVideoPlay.png"
                                      width="32px"
                                      height="32px"
                                    ></v-img>
                                    <!-- Complete -->
                                    <v-img
                                      v-if="
                                        dataItem.type == 'video' &&
                                        dataItem.isComplete == true
                                      "
                                      style="font-size: 37px !important"
                                      slot="prependIcon"
                                      color="#4FB14E"
                                      src="@/assets/rightgreen.png"
                                      width="32px"
                                      height="32px"
                                    ></v-img>
                                  </div>
                                </v-col>
                              </v-row>
                            </template>
                          </v-card>
                        </div>
                      </v-col>
                    </v-row>

                    <div class="mt-5" v-show="postTestFlag">
                      <v-col cols="12" class="pl-7 pr-8">
                        <div class="videoIconContainer">
                          <v-img
                            src="@/assets/Pre-Post-Test.png"
                            alt="Logo"
                            class="video_Icon"
                          />
                          <label class="labelVideo">
                            {{ $t("posttest") }}
                          </label>
                        </div>
                      </v-col>
                    </div>
                    <div class="ml-10 mr-10 mt-3">
                      <v-card
                        style="border-radius: 16px"
                        v-if="postTestFlag"
                        height="110px"
                        width="1204px"
                        class="d-flex justify-center align-center"
                        @click="
                          clickPostTest(PostTestdata, item.assignmentCourseID)
                        "
                      >
                        <template>
                          <v-row>
                            <v-col cols="9" md="9" lg="9" class="py-0">
                              <v-row align="center" no-gutters>
                                <v-col
                                  cols="auto"
                                  class="ml-3 py-1 d-flex align-center"
                                >
                                  <v-img
                                    v-if="
                                      PostTestdata.type === 'quiz' ||
                                      PostTestdata.type === 'preQuiz'
                                    "
                                    src="@/assets/images/posttesticon_0.png"
                                    class="card-image"
                                  ></v-img>
                                </v-col>
                                <v-col class="ml-5">
                                  <div class="text-left" style="color: #424242">
                                    <p style="font-size: 18px" class="mb-0">
                                      {{ PostTestdata.quizName }}
                                    </p>
                                    <p style="font-size: 13px" class="mb-0">
                                      {{ $t("questionsm") }} :
                                      <span
                                        style="font-weight: bold; color: black"
                                      >
                                        {{ PostTestdata.totalQuestion }}
                                      </span>
                                      {{ " / " }} {{ $t("limited") }} :
                                      <span
                                        style="font-weight: bold; color: black"
                                      >
                                        {{ PostTestdata.limitedSubmit }}
                                      </span>
                                    </p>
                                    <p style="font-size: 13px" class="mb-0">
                                      {{ $t("latestrating") }} :
                                      <span
                                        style="font-weight: bold; color: black"
                                      >
                                        {{ PostTestdata.latestRating }}
                                      </span>
                                    </p>
                                    <p style="font-size: 13px" class="mb-0">
                                      {{ $t("status") }} :
                                      <span
                                        v-if="PostTestdata.status === 'Pass'"
                                        style="
                                          color: #4fb050;
                                          font-weight: bold;
                                        "
                                      >
                                        {{ $t("pass") }}
                                      </span>
                                      <span
                                        v-else-if="
                                          PostTestdata.status === 'Fail'
                                        "
                                        style="
                                          color: #8a0000;
                                          font-weight: bold;
                                        "
                                      >
                                        {{ $t("fail") }}
                                      </span>
                                      <span v-else style="font-weight: bold">
                                        {{ $t("notstarted") }}
                                      </span>
                                    </p>
                                  </div>
                                  <!-- <div
                                    class="text-left mt-1"
                                    style="font-size: 18px; color: #424242"
                                  >
                                    {{ PostTestdata.quizName }} <br />
                                    <span style="font-size: 13px">
                                      Question: 10 / Limited 2 <br />
                                      Latest rating: 2 / 10
                                    </span>
                                  </div>
                                  <p style="font-size: 13px">
                                    Status:
                                    <span
                                      v-if="PostTestdata.completedCount === 0"
                                      style="color: red"
                                      >Fail</span
                                    >
                                    <span v-else style="color: green"
                                      >Pass</span
                                    >
                                  </p> -->
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="3"
                              md="3"
                              lg="3"
                              class="d-flex justify-end align-center"
                            >
                              <div style="padding-right: 20px">
                                <v-img
                                  v-if="
                                    PostTestdata.type == 'quiz' &&
                                    PostTestdata.completed == false
                                  "
                                  src="@/assets/postquest.png"
                                  width="33px"
                                  height="33px"
                                  style="margin-right: 2px !important"
                                ></v-img>
                                <v-img
                                  v-if="
                                    PostTestdata.type == 'preQuiz' &&
                                    PostTestdata.completed == false
                                  "
                                  src="@/assets/postquest.png"
                                  width="33px"
                                  height="33px"
                                  style="margin-right: 2px !important"
                                ></v-img>
                                <v-img
                                  v-if="
                                    PostTestdata.type == 'quiz' &&
                                    PostTestdata.completed == true &&
                                    PostTestdata.completedCount != 0
                                  "
                                  src="@/assets/rightgreen.png"
                                  width="33px"
                                  height="33px"
                                  style="font-size: 37px !important"
                                  slot="prependIcon"
                                  color="#4FB14E"
                                ></v-img>
                                <v-img
                                  v-if="
                                    PostTestdata.type == 'preQuiz' &&
                                    PostTestdata.completed == true &&
                                    PostTestdata.completedCount != 0
                                  "
                                  src="@/assets/rightgreen.png"
                                  width="33px"
                                  height="33px"
                                  style="font-size: 37px !important"
                                  slot="prependIcon"
                                  color="#4FB14E"
                                ></v-img>

                                <!-- selectedColor -->
                                <!-- Completed but fail quiz -->
                                <v-img
                                  v-if="
                                    PostTestdata.type == 'quiz' &&
                                    PostTestdata.completed == true &&
                                    PostTestdata.completedCount == 0
                                  "
                                  src="@/assets/redcross.png"
                                  width="33px"
                                  height="33px"
                                  style="font-size: 37px !important"
                                  slot="prependIcon"
                                  color="#D52A2A"
                                ></v-img>
                                <v-img
                                  v-if="
                                    PostTestdata.type == 'preQuiz' &&
                                    PostTestdata.completed == true &&
                                    PostTestdata.completedCount == 0
                                  "
                                  src="@/assets/redcross.png"
                                  width="33px"
                                  height="33px"
                                  style="font-size: 37px !important"
                                  slot="prependIcon"
                                  color="#D52A2A"
                                ></v-img>
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                      </v-card>
                    </div>
                  </div>
                </v-list-group>
              </v-list>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <videoPlay
      ref="videoPlay"
      :show="showVideoDialog"
      @close="showVideoDialog = false"
      :videoName="videoName"
      :videoPlayerDialog="videoPlayerDialog"
      @updateProgressCircle="updateProgressCircle"
      :videoFile="video_File"
      :contentid="contentId"
      :courseID="courseID"
      :lastWatchedTime="lastWatchedTime"
      :popupTime="popupTime"
      :formattedDateTime="formattedDateTime"
      :memberID="memberID"
      :video_page="2"
      :viewerFlag="viewerFlag"
    ></videoPlay>

    <v-dialog width="470" v-model="quizShow" persistent>
      <v-card
        style="overflow: hidden !important; border-radius: 16px !important"
      >
        <v-card-title style="font-size: 20px; color: #363636">
          <v-row class="mt-1 mb-1">
            <v-col style="text-align: center">
              <p class="mb-2">{{ $t(quizShowText) }}</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions style="justify-content: center">
          <v-btn
            @click="quizShow = false"
            color="#A6CC39"
            style="font-size: 20px; border-radius: 8px"
            class="text-capitalize white--text mb-2 mt-2 login-phone"
            >{{ $t("ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import videoPlay from "../Library/_subLibrary/_path/_video/videoPlay.vue";
import i18n from "@/translate/i18n";
export default {
  components: {
    videoPlay,
  },
  data() {
    return {
      item: {},
      search: "",
      searchtype: "",
      courseCategoryName: null,
      courseTemp: {},
      rq_courseId: 0,
      loading: true,
      quizShowText: null,
      quizShow: false,
      prequizCompleted: true,
      //video dialog data
      popupTime: 0,
      videoDescription: "",
      lastWatchedTime: "",
      videoName: "",
      contentId: 0,
      video_File: "",
      courseID: 0,
      memberID: "",
      //...................
      videoPlayerDialog: false,
      showVideoDialog: false,
      //...end of video dialog data
      //Videolink: "",
      //videoDialog: false,
      notStartedCourseID: [],
      inProgressCourseID: [],
      completedCourseID: [],
      delayCourseID: [],
      showCourseList: false,
      selectedColor: null,
      tempCourseItems: [], //raw uncategorize(not started, inprogress, etc...) courses
      courseChildItems: [],
      courseItems: [],
      viewerFlag: false,
      breadcrumbs: [
        {
          text: "course",
        },
      ],
      cardItems: [
        {
          bgcolor: "#008AFF",
          image: require("@/assets/images/notStarted.svg"),
          title: "notstarted",
          name: "notstarted",
          selected: false,
          courseCount: 0,
        },
        {
          bgcolor: "#E0BF0B",
          image: require("@/assets/images/inProgress.svg"),
          title: "inprogress",
          name: "inprogress",
          selected: false,
          courseCount: 0,
        },
        {
          bgcolor: "#4FB14E",
          image: require("@/assets/images/complete.svg"),
          title: "complete",
          name: "complete",
          selected: false,
          courseCount: 0,
        },
        {
          bgcolor: "#D52A2A",
          image: require("@/assets/images/delay.svg"),
          title: "delay",
          name: "delay",
          selected: false,
          courseCount: 0,
        },
      ],
      preTestFlag: false,
      PreTestdata: {},
      postTestFlag: false,
      PostTestdata: {},
      articleflag: false,
      // openclose: false,
      Articledata: {},
      formattedDateTime: "",
    };
  },
  computed: {
    
    textClass() {
      return this.$vuetify.breakpoint.mdAndUp ? "truncate" : "full-text";
    },
    // filteredCourseItems() {
    //   let searchTerm = this.search.toLowerCase();
    //   return this.courseItems.filter((courseItem) => {
    //     // Customize this condition based on your search criteria
    //     return courseItem.name.toLowerCase().includes(searchTerm);
    //   });
    // },
    searchInLists() {
      if (this.search) {
        let searchItems = [];
        searchItems = this.courseItems.filter((x) =>
          x.courseName.toLowerCase().includes(this.search.toLowerCase())
        );
        return searchItems;
      } else {
        return this.courseItems;
      }
    },
  },
  mounted() {
    (async () => {
      await this.getProgressCirleCourseData();
      await this.getProgressCountName();
      this.getNotificationCount();
      this.getFavourite();

      if (this.$route.params.reQuiz == "requiz") {
        this.loading = true;
        this.selectCourseCategory(this.$route.params.courseTemp);
      }

      return Promise.resolve();
    })().then(() => {
      this.mountDone = true;
    });
  },
  
  methods: {
    formatDate(changeDate) {
      // const date = new Date(dateString);
      // const options = { year: "numeric", month: "short", day: "numeric" };
      // return new Intl.DateTimeFormat("en-US", options).format(date);
      let returndata;
      let language = i18n.locale;

      if (language === "th") {        
        const formatter = new Intl.DateTimeFormat("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        returndata = formatter.format(new Date(changeDate));
      } else {       
        const formatter = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        returndata = formatter.format(new Date(changeDate));
      }

      return returndata;
    },
    updateProgressCircle() {
      switch (this.courseCategoryName) {
        case "notstarted":
          this.showCourseList = false;
          this.updateCourseCategoryMethodCall();
          break;
        case "inprogress":
          this.getCourseDetailFiles(this.courseID);
          break;
        case "delay":
          this.getCourseDetailFiles(this.courseID);
          break;
      }
    },
    formatDateTime(dateTime) {
      const options = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
      };
      const dateTimeString = dateTime.toLocaleDateString("en-US", options);
      return dateTimeString.replace(/\//g, "-");
    },
    switchVideoCase(item) {
      if (this.prequizCompleted === true) {
        let date1 = new Date();
        this.formattedDateTime = this.formatDateTime(date1);
        this.showVideoDialog = true;
        this.memberID = item.memberID;
        // this.$refs.videoPlay.GetcommendData(item.videoID, 2);
        this.$refs.videoPlay.GetCommentsForVideo(item.videoID);
        this.video_File = item.videoFile;
        this.contentId = item.videoID;
        this.videoName = item.videoName;
        this.viewerFlag = item.viewerFlag;
        if (item.progress == "1" || item.progress == "1.00") {
          this.lastWatchedTime = 0;
        } else {
          this.lastWatchedTime = item.lastViewTime;
        }
        this.courseID = item.assignmentCourseID;
        this.popupTime = item.popupTime * 60; //change mins to seconds
      } else {
        this.quizShowText = "lblpretestalert";
        this.quizShow = true;
      }
    },
    clickPostTest(item, courseid) {
      if (item.fullLimit !== false) {
        this.quizShowText = "quizfulllimittext";
        this.quizShow = true;
        return false;
      } else if (item.canDoInprogress !== false) {
        this.quizShowText = "quizcandoinprogresstext";
        this.quizShow = true;
        return false;
      } else if (item.canDoWatchFinish !== false) {
        this.quizShowText = "quizfinishwatchtext";
        this.quizShow = true;
        return false;
      } else {
        this.$router.push({
          name: "course-quiz",
          query: {
            quizId: item.quizId,
            rq_courseId: courseid,
            courseTemp: this.courseTemp,
            quizIsPassed: item.completedCount, // 1 = pass 1 time, 2 = pass 2 times
            quizTypeID: item.quizTypeID,
          },
        });
      }
    },
    clickArticle(item) {
      if (this.prequizCompleted === true) {
        this.$router.push({
          name: "course-article",
          query: {
            assignmentArticleID: item.assignmentArticleID,
            courseID: item.courseID,
            memberID: item.memberID,
          },
        });
      } else {
        this.quizShowText = "lblpretestalert";
        this.quizShow = true;
      }
    },
    checkContentType(item) {
      this.switchVideoCase(item);
    },
    clickPreTest(item, courseid) {
      let self = this;
      if (item.fullLimit !== false) {
        self.quizShowText = "quizfulllimittext";
        self.quizShow = true;
        return false;
      } else {
        self.$router.push({
          name: "course-quiz",
          query: {
            quizId: item.quizId,
            rq_courseId: courseid,
            courseTemp: self.courseTemp,
            quizIsPassed: item.completedCount, // 1 = pass 1 time, 2 = pass 2 times
            quizTypeID: item.quizTypeID, //pre-test = 2, post-test = 1
          },
        });
      }
    },

    closeDialog() {
      this.showVideoDialog = false;
      this.$refs.videoPlayer.pause();
    },

    async getCourseDetailFiles(course_id) {
      //item

      let self = this;
      self.courseChildItems = [];
      self.preTestFlag = false;
      self.PreTestdata = {};
      self.postTestFlag = false;
      self.PostTestdata = {};
      self.Articledata = {};
      self.articleflag = false;
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        companyId: localStorage.getItem("companyID"),
        courseId: course_id,
      };
      await self.$axios
        .post(`${self.web_urlV7}Contents/GetAssignmentVideoList`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            let courseVideoFiles = res.data.data.progressData.map((v) => ({
              ...v,

              fileName: v.videoName,
              type: "video",
              title: v.title,
            }));
            if (res.data.data.article) {
              self.articleflag = true;

              self.Articledata = {
                fileName: res.data.data.article.title,
                type: "article",
                assignmentArticleID: res.data.data.article.assignmentArticleID,
                courseID: res.data.data.article.courseID,
                isCompleted: res.data.data.article.isCompleted,
                optimizedLink: res.data.data.article.optimizedLink,
                memberID: courseVideoFiles[0]?.memberID,
              };
            }

            if (res.data.data.quiz) {
              self.postTestFlag = true;
              self.PostTestdata = {
                fileName: "Post-Quiz",
                type: "quiz",
                canDoInprogress: res.data.data.quiz.canDoInprogress, // || null
                canDoWatchFinish: res.data.data.quiz.canDoWatchFinish,
                completed: res.data.data.quiz.completed,
                completedCount: res.data.data.quiz.completedCount,
                fullLimit: res.data.data.quiz.fullLimit,
                quizId: res.data.data.quiz.quizId,
                quizTypeID: 1,
                quizName: res.data.data.quiz.quizName,
                totalQuestion: res.data.data.quiz.totalQuestion,
                limitedSubmit: res.data.data.quiz.limitedSubmit,
                latestRating: res.data.data.quiz.latestRating,
                status: !res.data.data.quiz.completed
                  ? "Not Started"
                  : res.data.data.quiz.completedCount
                  ? "Pass"
                  : "Fail",
              };
            } else {
              self.postTestFlag = false;
            }

            if (res.data.data.preQuiz) {
              self.preTestFlag = true;
              self.prequizCompleted = res.data.data.preQuiz.completed;
              self.PreTestdata = {
                fileName: "Pre-Quiz",
                type: "preQuiz",
                canDoInprogress: res.data.data.preQuiz.canDoInprogress, // || null
                canDoWatchFinish: res.data.data.preQuiz.canDoWatchFinish,
                completed: res.data.data.preQuiz.completed,
                completedCount: res.data.data.preQuiz.completedCount,
                fullLimit: res.data.data.preQuiz.fullLimit,
                quizId: res.data.data.preQuiz.quizId,
                quizTypeID: 2,
                quizName: res.data.data.preQuiz.quizName,
                totalQuestion: res.data.data.preQuiz.totalQuestion,
                limitedSubmit: res.data.data.preQuiz.limitedSubmit,
                latestRating: res.data.data.preQuiz.latestRating,
                status: !res.data.data.preQuiz.completed
                  ? "Not Started"
                  : res.data.data.preQuiz.completedCount
                  ? "Pass"
                  : "Fail",
              };
            } else {
              self.prequizCompleted = true;
              self.preTestFlag = false;
            }

            self.courseChildItems = courseVideoFiles;
            self.rq_courseId = course_id;

            self.checkCourseComplete(res.data.data);
          }
        });
    },

    checkCourseComplete(responseData) {
      let self = this;
      let allVideoIsCompleted = null;
      let quizIsCompleted = null;
      let prequizIsCompleted = null;
      let articleIsCompleted = null;

      if (self.courseCategoryName != "complete") {
        allVideoIsCompleted = responseData.progressData.find(
          (j) => j.isComplete === false || j.isComplete == null
        )
          ? "false"
          : "true";
        articleIsCompleted =
          responseData.article !== null
            ? responseData.article.isCompleted
            : true;
        quizIsCompleted =
          responseData.quiz != null ? responseData.quiz.completed : true;
        prequizIsCompleted =
          responseData.preQuiz != null ? responseData.preQuiz.completed : true;

        if (
          prequizIsCompleted === true &&
          allVideoIsCompleted === "true" &&
          quizIsCompleted === true &&
          articleIsCompleted === true
        ) {
          self.showCourseList = false;
          self.updateCourseCategoryMethodCall();
        }
      }
    },

    async updateCourseCategoryMethodCall() {
      this.loading = true;
      await this.getProgressCirleCourseData();
      await this.getProgressCountName();
    },

    async getProgressCountName() {
      let self = this;
      const request = {
        userId: parseInt(localStorage.getItem("UserID")),
        companyId: localStorage.getItem("companyID"),
      };

      await self.$axios
        .post(`${self.web_urlV7}Contents/GetProgressCountName`, request)
        .then(function (res) {
          // console.log("res.data.data", res.data.data);
          if (res.data.status == 0) {
            self.tempCourseItems = res.data.data.map((v) => ({
              ...v,
              fileTotal:
                v.assignmentQuizCount +
                v.assignmentVideoCount +
                v.assignmentArticleCount,
              isOpen: false,
            }));

            self.loading = false;
          }
        });
    },

    async getProgressCirleCourseData() {
      let self = this;
      const request = {
        userId: parseInt(localStorage.getItem("UserID")),
        companyId: localStorage.getItem("companyID"),
      };

      await self.$axios
        .post(`${self.web_urlV4}Contents/GetUserProgressMobileFrist`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            self.cardItems.forEach((value) => {
              value.opencolse = false;
              if (value.name == "notstarted") {
                value.courseCount = res.data.data.notStarted.length;
              } else if (value.name == "inprogress") {
                value.courseCount = res.data.data.inProgress.length;
              } else if (value.name == "complete") {
                value.courseCount = res.data.data.completed.length;
              } else {
                value.courseCount = res.data.data.delay.length;
              }
            });

            self.notStartedCourseID = res.data.data.notStarted;
            self.inProgressCourseID = res.data.data.inProgress;
            self.completedCourseID = res.data.data.completed;
            self.delayCourseID = res.data.data.delay;
          }
        });
    },

    selectCourseCategory(v) {
      this.showCourseList = v.courseCount != 0 ? !v.selected : false;
      this.cardItems.forEach((value) => {
        if (value.name == v.name) {
          value.selected = !value.selected;
        } else {
          value.selected = false;
        }
      });
      this.selectedColor = v.bgcolor;

      if (this.showCourseList === true) {
        let rawData = this.tempCourseItems;

        let categorized = [];

        switch (v.name) {
          case "notstarted":
            categorized = rawData.filter((elem) => {
              return this.notStartedCourseID.some((ele) => {
                return ele.assignmentCourseID === elem.assignmentCourseID;
              });
            });
            break;
          case "inprogress":
            categorized = rawData.filter((elem) => {
              return this.inProgressCourseID.some((ele) => {
                return ele.assignmentCourseID === elem.assignmentCourseID;
              });
            });
            break;
          case "complete":
            categorized = rawData.filter((elem) => {
              return this.completedCourseID.some((ele) => {
                return ele.assignmentCourseID === elem.assignmentCourseID;
              });
            });
            break;
          case "delay":
            categorized = rawData.filter((elem) => {
              return this.delayCourseID.some((ele) => {
                return ele.assignmentCourseID === elem.assignmentCourseID;
              });
            });
            break;
        }
        this.courseCategoryName = v.name;

        this.courseTemp = {
          bgcolor: v.bgcolor,
          courseCount: v.courseCount,
          image: v.image,
          name: v.name,
          selected: false,
          title: v.title,
        };

        if (this.$route.params.reQuiz == "requiz") {
          this.getCourseDetailFiles(this.$route.params.rq_courseId);
          this.courseItems = categorized.map((x) => ({
            ...x,
            isOpen: this.$route.params.rq_courseId == x.assignmentCourseID,
          }));

          this.loading = false;
          this.$route.params.reQuiz = null;
        } else {
          this.courseItems = categorized.map((x) => ({
            ...x,
            isOpen: false,
          }));
        }
      }
    },
  },
};
</script>

<style scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.iconmdileftright {
  margin-left: 20px;
}
.truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-height: 1.5;
  max-height: calc(1.4em * 1);
  white-space: normal;
}

.full-text {
  white-space: normal;
}

::v-deep
  .list-Bg
  .v-list-group__header.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: rgba(0, 0, 0, 0.87);
  background-color: #42424214;
  height: 110px;
  border-radius: 16px;
}

::v-deep
  .list-Bg
  .v-list-group__header.v-list-item.v-list-item--active.v-list-item--link.theme--light {
  color: rgba(0, 0, 0, 0.87);
  background-color: #42424214;
  height: 100px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.custom-padding {
  border-radius: 8px;
  border-color: #c0c0c0;
  background-color: #42424214;
}
.description-text {
  font-size: 18px;
  color: #424242;
  text-align: right;
}
.card-container {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to the next line if needed */
  justify-content: space-between;
}
::v-deep
  .theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  background-color: #42424208;
}

.courseImg {
  position: absolute;
  top: 35%;
  left: 48%;
  transform: translate(-50%, -48%);
}
.coursecounttext {
  text-align: center;
  font-size: 16px;
  color: #424242;
  margin-top: -13px;
}
.imgBg {
  height: 130px;
  opacity: 0.1;
  margin-bottom: 10px;
  border-radius: 8px;
  position: relative;
  z-index: 0 !important;
}
.countIconBtn {
  background-color: #ffffff;
  border-radius: 50px !important;
  box-shadow: unset;
}
::v-deep .primary--text {
  color: #424242 !important;
  caret-color: #424242 !important;
}
::v-deep .countIconBtn.theme--light.v-btn {
  color: #ffffff;
}
::v-deep .countIconBtn.theme--light.v-btn.v-btn--has-bg {
  background-color: #ffffff;
}
::v-deep .countIconBtn.v-btn--absolute.v-btn--right,
.v-btn--fixed.v-btn--right {
  right: -10px;
}
::v-deep .countIconBtn.v-btn--fab.v-size--x-small {
  height: 42px;
  width: 42px;
}
::v-deep .countIconBtn.v-btn--fab.v-size--x-small.v-btn--absolute.v-btn--top {
  top: -8px;
}
</style>

<style scoped>
::v-deep .overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar {
  width: 7px !important;
}
/* Track */
::v-deep .overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar-track {
  width: 6px !important;
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}
/* Handle */
::v-deep .overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 2px #e6e6e6;
  border-radius: 7px;
}
::v-deep .overflow-y-auto.v-sheet.theme--light {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 0px;
}
::v-deep .v-list {
  padding: 0;
}
.containerimage {
  margin-top: 3px;
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to the next line if needed */
  justify-content: space-between;
}

.card-image {
  max-width: 156px;
  min-height: 79px;
  border-radius: 8px;
  object-fit: cover; /* This ensures the image covers the entire area */
}

.my-card {
  border-radius: 12px;
  overflow: hidden;
}
.videoIconContainer {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  align-items: center;
  padding-left: 15px;
  /**......... */
  height: 36px;
  width: 100%;
  cursor: pointer;
  background-color: #6fd73e;
}

.video_Icon {
  max-height: 20px;
  max-width: 17px;
  object-fit: contain;
}

.labelVideo {
  font-size: 16px;
  color: #ffffff;
  margin-left: 8px;
  margin-top: 2px;
}
.detail-child-img {
  width: 156px;
  height: 79px;
  object-fit: cover;
}

.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 50px;
}
.v-list-item__icon {
  align-self: flex-start;
  margin: 10px 0;
}
.article-container {
  width: 35px !important;
  height: 35px !important;
}
.course-image {
  max-width: 156px;
  min-height: 79px;
  border-radius: 8px;
}
.article-image {
  max-width: 156px;
  min-height: 79px;
  max-height: 88px;
  border-radius: 8px;
}
.course-imagearticle {
  max-width: 156px;
  min-height: 79px;
  border-radius: 8px;
  width: 100%;
  height: auto;
}
.textWrappingStyle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
